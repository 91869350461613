import React from 'react';
import Layout from '../components/layout';
import Seo from "../components/seo"
import CTA from '../components/cta';

import BlogFeed from '../components/blog-feed';

import patternHex from '../images/backrounds/pattern-hex-white.jpg';
import NeedAQuote from "../components/need-a-quote";
import {StaticImage} from "gatsby-plugin-image";
import Quote from "../components/quote";


const recentCustomers = [
  <StaticImage
    src="../images/clients/zendesk-customers/genomix.png"
    className="m-auto"
    alt="logo Genomics"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/accureg.png"
    className="m-auto"
    alt="logo_accureg"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/singularity.png"
    className="m-auto"
    alt="logo_Singularity"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/learnondemand.png"
    className="m-auto"
    alt="logo_learnondemand"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/kindred_bravely.png"
    className="m-auto"
    alt="logo_kindred_bravely"
    placeholder="blurred"
  />,
  <StaticImage
    src="../images/clients/zendesk-customers/kidkraft.png"
    className="m-auto"
    alt="logo_kidkraft"
    placeholder="blurred"
  />,
];

const Index = ({location}) => (
  <Layout>
    <Seo
      pathName={location.pathname}
      title="Zendesk Everything"
      description="729 Solutions offers services for certified admins, developers, and experienced Zendesk project managers who can help you with any requirements you have."
    />
    <section>
      <div
        className="hero"
        style={{
          backgroundImage: `url(${patternHex})`
        }}
      >
        <div className="hero-title bg-dark-transparent">
          <div className="hero-image">
            <StaticImage
              src="../images/clients/zendesk.png"
              alt="Zendesk Implementation Premier Partner"
              layout="constrained"
              style={{ maxWidth: "200px"}}
            />
          </div>
          <h1 className="text-white">
            729 solutions is here for all of your zendesk needs
          </h1>
          <p className="text-white">
            So you want to use Zendesk and need some general help? You have come to the right place. 729 Solutions can
            offer all Zendesk services by the hour so you know you are never wasting time and get exactly the services
            you need. We have certified admins, developers, and veteran Zendesk project managers who can help you with
            any requirements you have.</p>
            <CTA href="/contact-us/" className="btn btn-primary" text="Tell Us About Your Project"/>
        </div>
      </div>
    </section>
    <section>
      <div className="container py-6">
        <div className="row">
          <div className="col-lg-6">
            <h3>we will collaborate with you to understand your business goals​ and metrics to help you make the most of
              zendesk</h3>
          </div>
          <div className="col-lg-6">
            <h2>
              some typical things customers need:
            </h2>
            <ul className="text-primary m-0 pl-3">
              <li><span className="text-dark">Improve self serve and deflection</span></li>
              <li><span className="text-dark">Segment users and handle tickets by customer value</span></li>
              <li><span className="text-dark">Get better insight into agent performance</span></li>
              <li><span className="text-dark">Increase automations</span></li>
              <li><span className="text-dark">Connect Zendesk to other software</span></li>
              <li><span className="text-dark">Improve escalations and workflows</span></li>
              <li><span className="text-dark">Add chat, talk, and other channels</span></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="container pb-6">
        <div className="row">
          <div className="col">
            <h2>recent zendesk customerS</h2>
          </div>
        </div>
        <div className="row align-items-center">
          {recentCustomers.map((component) => (
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div className="row">
                    <div className="col-4 col-lg-12 ml-auto mr-auto mb-5">
                      {component}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="bg-dark">
      <div className="container py-6">
        <div className="row">
          <div className="col">
            <h2 className="text-center text-white">Testimonials</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Quote
              dark
              name="Noopur Shankar"
              company="Optimal Strategies"
              quote="The 729 Solutions team made great efforts to become aware of our needs and were fast learners."
            />
          </div>
        </div>
      </div>
    </section>
    <NeedAQuote/>
    <BlogFeed containerClassname="py-6"/>
  </Layout>
);

export default Index;
